.bug-report{
  padding: 2% 3%;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}

.table-bugs-wrapper {
  background-color: #fff;
  padding: 1rem;
  margin-top: 1rem;
  color: black !important;
}

.table-bugs th {
  color: var(--color-primary) !important;
  min-width: 200px;
}

.bugs-content {
  margin-top: 1.5rem;
  color: black !important;
}

.bugs-content > .form {
  margin-bottom: 1.5rem;
}

.bugs-content > .form > label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}

.bugs-content .button-send {
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.bugs-content .button-loading {
  cursor: not-allowed;
  opacity: 0.8;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.bugs-content .button-send:hover {
  opacity: 0.8;
}

.bugs-content .button-send > i {
  margin-right: 0.5rem;
}

.rdw-editor-toolbar {
  background-color: transparent !important;
  border: none !important;
}
.rdw-option-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1.25rem 1rem !important;
}

.rdw-editor-toolbar {
  padding: 0px !important;
}

.rdw-option-wrapper:first-child {
  margin-left: 0px !important;
}

.rdw-dropdown-wrapper {
  padding: 1.25rem 0px !important;
}

.rdw-dropdown-wrapper > a {
  color: black;
  padding-left: 0.5rem;
}

.text-editor {
  background-color: #fff;
  padding: 0px 1rem;
  min-height: 60vh;
  border: 1px solid var(--color-stroke);
  border-radius: 5px;
}
