.mfa {
  font-family: 'Rubik', sans-serif;
  width: 35rem !important;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0px 12px 64px rgb(0 0 0 / 50%);
  border-radius: 0.5rem;
}

.mfa .mfa-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mfa .mfa-header>img {
  width: 7rem;
  margin-bottom: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.mfa .mfa-header>span {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-blue-4);
  display: block;
  margin-bottom: 4rem;
}

.mfa .mfa-header h1 {
  color: var(--color-blue-1);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.mfa .mfa-header h2 {
  color: var(--color-active);
  font-size: 1.2em;
}

.mfa .mfa-header>p {
  color: var(--color-text-grey);
  margin-bottom: 2rem;
}

.mfa .ant-alert-error {
  background-color: HSL(0, 84%, 95%) !important;
}

.mfa .ant-alert-message {
  color: HSL(0, 83%, 55%) !important;
}

.mfa .form-wrapper-mfa {
  margin: 0.5rem 0 2.5rem 0;
}

.mfa .form-wrapper-mfa > span {
  color: var(--color-label);
  margin-bottom: 0.5rem;
  display: inline-block;
}


.mfa .form-wrapper-mfa>.buttons>.mfa-button {
  display: block;
  color: #fff;
  font-size: 1rem;
  padding: 0.8rem 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  background: #64D5D2;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  border: none;
  font-family: 'Inter', sans-serif;
  border-radius: .5rem;
  margin-top: 2rem;
}

.mfa .form-wrapper-mfa>.buttons>.back {
  display: block;
  margin-top: 0.2rem;
  color: #64D5D2;
  font-size: 1rem;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  font-weight: 700;
  background: #fff;
  /* border: 2px solid #53d7f5; */
  border: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  text-align: center;
}

.mfa .form-wrapper-mfa>.buttons>.back > i {
  margin-right: .5rem;
}

.mfa>.form-wrapper-mfa>.buttons {
  position: relative;
}


/* added by me */
.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
}

.mfa ul.step {
  margin: 0px;
  padding: 0px;
}

.mfa ul.step>li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: var(--color-black);
  position: relative;
}

.mfa ul.step>li>.btn-primary-ghost {
  color: var(--primary-button);
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px .25rem;
  cursor: pointer;
}

.mfa ul.step>li>span {
  background-color: var(--secondary-button);
  display: flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: .6rem;
  color: #fff;
  margin-right: 0.5rem;
}

.mfa ul.step>li:not(:last-child)::before {
  content: '';
  height: 0.85rem;
  width: 2px;
  background-color: var(--color-step);
  margin: 0.35rem 0px;
  position: absolute;
  left: 0.45rem;
  bottom: -1.3rem;
}

.scan {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  color: #2C82F6;
}

.scan p {
  margin-left: 0.5em;
}

.back {
  width: 100%;
  color: #64D5D2;
  display: flex;
  justify-content: center;
}

.back a {
  color: #64D5D2;
}

.ant-input-affix-wrapper {
  border-radius: 5px !important;
  /* margin-top: 0.5em; */
}

.mfa .ant-input-affix-wrapper>input.ant-input {
  padding: 1em 0.5em !important;
}