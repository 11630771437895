.overviewanalytic {
    width: 100%;
    padding: 3rem;
    background-color: #FBFBFB;
  }
  
  
  .overviewanalytic .overviewanalytic-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: flex-start;
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left {
    display: flex;
    align-items: center;
  }

  .overviewanalytic .ant-picker {

    /* width: 12rem !important; */
    padding: 0.6rem 1rem;
    
    /* background-color: aqua; */
    border-color: var(--color-light-grey) !important;
    border-radius: 0.5rem !important;
    color: var(--color-active);
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left .playlist-wrapper {
    display: flex;
    margin-left: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    background-color: #fff;
    border-radius: 0.35rem;
    border: 1px solid var(--color-border);
    padding: 0.5rem 0.7rem;
    position: relative;
    cursor: pointer;
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left .playlist-wrapper i.playlist-open {
    transform: rotate(180deg);
    transition: all 250ms ease-in-out;
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left .playlist-wrapper i.playlist-closed {
    transform: rotate(0deg);
    transition: all 250ms ease-in-out;
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left .playlist-wrapper .playlist-selection {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .overviewanalytic .overviewanalytic-header .overviewanalytic-header-left .playlist-wrapper .playlist-selection > span {
    margin-left: 0.25rem;
    font-weight: 600;
    color: var(--color-black);
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar {
    display: flex;
    align-items: center;
    width: max-content;
    border-radius: 0.35rem;
    border: 1px solid var(--color-border);
    padding: 0.5rem 0.7rem;
    background-color: #fff;
    margin: 0px;
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar > i {
    font-size: 1.5em;
    color: var(--color-text-placeholder);
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar > input.search-input {
    border: none;
    background-color: transparent;
    font-family: 'Inter', sans-serif;
    width: 200px;
    color: var(--color-black);
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar > input.search-input::placeholder {
    color: var(--color-text-placeholder);
  }
  
  .overviewanalytic .overviewanalytic-header .searchbar > input.search-input:focus {
    outline: none;
  }
  
  .overviewanalytic .overviewanalytic-header .btn-primary {
    color: #fff;
    background-color: var(--primary-button);
    border-radius: 0.35rem;
    padding: 0.5rem 1rem;
    height: auto;
    border: none;
    font-weight: 600;
    box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
    cursor: pointer;
  }
  
  .overviewanalytic .overviewanalytic-header .btn-primary:hover {
    opacity: 0.8;
  }
  
  .overviewanalytic .overviewanalytic-header .actions__wrapper {
    display: flex;
    column-gap: 12px;
  }
  
  .overviewanalytic .overviewanalytic-header .actions__wrapper .overviewanalytic__delete {
    border-radius: 10px;
    background-color: #ff4d4f;
    color: #fff;
  }
  
  .overviewanalytic .overviewanalytic-header .actions__wrapper .overviewanalytic__delete:hover {
    background-color: #ff7875;
  }
  
  .overviewanalytic .overviewanalytic-subheader {
    display: flex;
    justify-content: flex-end;
  }
  
  .overviewanalytic .overviewanalytic-subheader .overviewanalytic-button-group > button.active {
    background-color: var(--color-active);
  }
  
  .overviewanalytic .overviewanalytic-subheader .overviewanalytic-button-group > button {
    border: none;
    background-color: var(--color-secondary);
    color: #fff;
    padding: 0.25rem 0.8rem;
    cursor: pointer;
  }
  
  .overviewanalytic .overviewanalytic-subheader .overviewanalytic-button-group > button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .overviewanalytic .overviewanalytic-subheader .overviewanalytic-button-group > button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .overviewanalytic .overviewanalytic-content {
    display: flex;
    justify-content: flex-start;
    column-gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    padding: 2rem;
  }

  .table-name {
    display: flex;
    flex-direction: row;
    color: var(--color-active) !important;
    font-weight: 700 !important;
  }

  .table-name .male{
    color: var(--secondary-button);
    margin-right: 12px;
  }
  .table-name .female{
    color:#FF7066;
    margin-left: 12px
    ;
  }
  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard{
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-rows: 1fr;
    gap: 2rem; /* add some gap between grid items if desired */
  }
  .overviewanalytic .overviewanalytic-chart{
    width: 100%;
    box-shadow: 2rem 2rem 3rem rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    padding: 1rem;
    margin-top: 2rem;
    background-color: #FFFFFF;
    height: fit-content;
  }
  .overviewanalytic .overviewanalytic-row-chart{
    /* width: 100%; */
    box-shadow: 2rem 2rem 3rem rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    padding: 1rem;
    margin-top: 2rem;
    background-color: #FFFFFF;
    height: fit-content;
    display: flex;
    flex: 1;
  }


  .gender-summary .male-summary{
    display: flex;
    flex-direction: row;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding-right: 85px;
    margin: 20px;
    
  }
  .gender-summary .male-summary .male-icon{
    border-radius: 100px / 100px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
    
  }

  .gender-summary .male-summary h3{
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 12px;

  }
  .gender-summary .male-summary p{
   
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-active);


  }
  .gender-summary .male-summary .male-data{
    display: flex;
    flex-direction: column;

  }



  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard .dashboard-card{
    display: flex;
    flex-direction: row;
    box-shadow: 2rem 2rem 3rem rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    justify-content: flex-start;
    padding: 1.5rem;
    background-color:#FFFFFF ;
  }
  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard .dashboard-card .dashboard-icon{
    border-radius: 100px / 100px;
    padding: 1rem;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard .dashboard-card .dashboard-data{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2rem;
    
  }
  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard .dashboard-card .dashboard-data h2{
    color: var(--color-text-light);
    font-size: 1rem;
    font-weight: 600; 
  }
  .overviewanalytic .overviewanalytic-content .overviewanalytic-dashboard .dashboard-card .dashboard-data h4{
    color: var(--color-active);
    font-size: 1.5rem;
    font-weight: 700; 
    line-height: 1.5rem;
  }
  
  .overviewanalytic .overviewanalytic-content .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    column-gap: 12px;
    margin-bottom: 1.5rem;
  }
  .overviewanalytic .overviewanalytic-content .empty-content .empty-title{
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .overviewanalytic .overviewanalytic-content .empty-content p{
    font-size: 1rem;
    color: var(--color-light-grey);
  }
  
  .overviewanalytic .title {
    color: var(--color-primary);
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  
  .overviewanalytic .overviewanalytic-content .overviewanalytic-card {
    /* width: 25%; */
    width: 100%;
    /* width: 100%; */
    position: relative;
    padding: 1rem 0.5rem;
    margin-right: 0 !important;
  }
  
  .overviewanalytic .overviewanalytic-content .overviewanalytic-card:hover .overviewanalytic-overlay {
    opacity: 1;
  }
  
  /* .overviewanalytic .overviewanalytic-content .overviewanalytic-card:not(:last-child) {
    margin-right: 1rem;
  } */
  
  .overviewanalytic .overviewanalytic-content .overviewanalytic-card > img,
  .overviewanalytic .overviewanalytic-content .overviewanalytic-card > video {
    width: 300px;
    height: 400px;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
    border: 1px solid var(--color-stroke);
  }
  
  
  .overviewanalytic .overviewanalytic-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
  
  .overviewanalytic .empty-overviewanalytic-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
  }
  
  .overviewanalytic .overviewanalytic__wrapper {
    border: 5px;
  }
  
  .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__header {
    background-color: var(--color-primary);
  }
  
  .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__header:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__header:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__header
    .ant-collapse-header:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.3s all ease;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__header
    .ant-collapse-header {
    color: #fff;
  }
  
  .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    padding: 0.5rem 1rem;
  }
  
  @overviewanalytic (min-width: 900px) {
    .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__card {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @overviewanalytic (min-width: 1200px) {
    .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__card {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @overviewanalytic (min-width: 1400px) {
    .overviewanalytic .overviewanalytic__wrapper .overviewanalytic__card {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .overviewanalytic__card-item {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
    z-index: 9999999;
    flex-direction: column;
    border: 1px solid var(--color-stroke);
    white-space: pre-wrap !important;
    overflow: hidden;
    pointer-events: auto !important;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-family: Roboto, sans-serif;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar-wrapper {
    display: flex;
    align-items: center;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar-logo {
    margin-right: 12px;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar-edit {
  }
  

  .overviewanalytic .leaderboard-export {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem 0rem;
  }
  .playlist
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-calendar-edit-logo {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-description {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    line-height: normal;
    z-index: 30;
    width: 100%;
    padding-bottom: 2.2em;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-description
    span {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
  
  .overviewanalytic
    .overviewanalytic__wrapper
    .overviewanalytic__card
    .overviewanalytic__card-description
    p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    display: block;
    white-space: pre-wrap;
  }
  
  .overviewanalytic__card-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  
  .overviewanalytic__actions {
    z-index: 30;
  }
  .ant-popover-inner-content {
    padding: 0.5rem 0.5rem !important;
  }
  
  .overviewanalytic__overviewanalytic-popover {
    display: flex;
    column-gap: 0.25rem;
  }
  
  .overviewanalytic__overviewanalytic-preview {
    padding: 0.25rem 0.5rem;
    border-bottom-left-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: var(--color-primary);
    color: #fff;
  }
  
  .overviewanalytic__overviewanalytic-delete {
    padding: 0.25rem 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    border-bottom-left-radius: 10px;
    background-color: hsla(0, 62%, 55%, 1);
    color: #fff;
    border-left: 1px solid #fff;
  }
  
  .overviewanalytic__overviewanalytic-more {
    padding: 0.25rem 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: hsl(212, 46%, 25%);
    color: #fff;
    border-left: 1px solid #fff;
  }
  
  .img-overlay {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 80%;
    bottom: 0px !important;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.56) 100%
    );
  }
  
  .overviewanalytic__card-img {
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  
  .overviewanalytic__card-video {
    z-index: 10;
  }
  
  .overviewanalytic__card-info {
    z-index: 30;
    cursor: default;
    text-align: center;
  }
  
  .overviewanalytic__card-type {
    background-color: hsl(168, 76%, 36%);
    color: #fff;
    padding: 0.25rem 0.5rem;
    display: inline-block;
    border-bottom-right-radius: 10px;
  }
  
  .overviewanalytic__card-order {
    background-color: var(--color-primary);
    color: #fff;
    padding: 0.25rem 0.5rem;
    display: inline-block;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options {
    display: flex;
    margin-bottom: 12px;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__checkbox {
    display: flex;
    column-gap: 12px;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__action-buttons {
    display: flex;
    column-gap: 12px;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__action-buttons
    .overviewanalytic__options
    .overviewanalytic__checkbox
    .overviewanalytic__checkbox-title {
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__add-file-btn {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 10px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__add-file-btn:hover {
    opacity: 0.8;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__set-time-btn {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 10px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__set-time-btn:hover {
    opacity: 0.8;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__delete-file-btn {
    color: #fff;
    border-radius: 10px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 500;
  }
  
  .overviewanalytic
    .overviewanalytic__header
    .overviewanalytic__panel
    .overviewanalytic__options
    .overviewanalytic__action-buttons
    > .overviewanalytic__delete-file-btn:hover {
    opacity: 0.8;
  }
  
  .overviewanalytic .overviewanalytic__card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 0.5rem 1rem;
  }
  
  /* .overviewanalytic .overviewanalytic__card .overviewanalytic__card-item {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-stroke);
    cursor: default;
    align-items: center;
    justify-content: center;
  }
  
  .overviewanalytic .overviewanalytic__card .overviewanalytic__card-item .ant-tag {
    z-index: 5;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  
  .overviewanalytic .overviewanalytic__card .overviewanalytic__card-item ::selection {
    background: none !important;
    color: none !important;
  } */
  
  .overviewanalytic__card-item:active,
  .overviewanalytic__card-item:hover {
    cursor: grab;
  }
  
  .overviewanalytic__card-item:active,
  .overviewanalytic__card-item:hover {
    cursor: grabbing !important;
  }
  
  .overviewanalytic__card-item .ant-tag {
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  
  .overviewanalytic__card-item ::selection {
    background: none !important;
    color: none !important;
  }
  
  .overviewanalytic__card-item .img-overlay {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 80%;
    bottom: 0px !important;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.56) 100%
    );
  }
  
  .overviewanalytic__card-item .checked-overlay {
    position: absolute;
    z-index: 100;
    inset: 0;
    background: hsla(209, 53%, 28%, 0.58) !important;
  }
  
  .overviewanalytic__card-item .ant-tag-checkable-checked {
    background: hsla(209, 53%, 28%, 0.58) !important;
  }
  
  .overviewanalytic__card-item .checked-overlay > .icon-checked {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .overviewanalytic__card-calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .overviewanalytic__card-calendar-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    font-family: Roboto, sans-serif;
  }
  
  .overviewanalytic__card-calendar-wrapper {
    display: flex;
    align-items: center;
  }
  
  .overviewanalytic__card-calendar-logo {
    margin-right: 12px;
  }
  
  .overviewanalytic__card-calendar-edit {
  }
  
  .overviewanalytic__card-calendar-edit-logo {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .overviewanalytic__card-description {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    line-height: normal;
    z-index: 30;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -52.78%,
      rgba(0, 0, 0, 0.56) 100%
    );
  }
  
  .overviewanalytic__card-description span {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    display: block;
    white-space: pre-wrap;
  }
  
  .overviewanalytic__card-description p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    display: block;
    white-space: pre-wrap;
  }
  
  /* .overviewanalytic .overviewanalytic__card .overviewanalytic__card-item .img-overlay {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 80%;
    bottom: 0px !important;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.56) 100%
    );
  }
  
  .overviewanalytic .overviewanalytic__card .overviewanalytic__card-item .checked-overlay {
    position: absolute;
    z-index: 100;
    inset: 0;
    background: hsla(209, 53%, 28%, 0.58) !important;
  }
  
  .overviewanalytic .overviewanalytic__card .ant-tag-checkable-checked {
    background: hsla(209, 53%, 28%, 0.58) !important;
  }
  
  .overviewanalytic
    .overviewanalytic__card
    .overviewanalytic__card-item
    .checked-overlay
    > .icon-checked {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
  
  .overviewanalytic-overlay-button-group {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
    z-index: 50;
    padding: 10px;
  }
  
  .overviewanalytic-overlay-button-group > button,
  .overviewanalytic-overlay-button-group > a {
    cursor: pointer;
    border: none;
    background-color: #fff;
    color: var(--color-secondary);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    display: inline-block;
    font-size: 1.2em;
  }
  
  .mr-2 {
    margin-right: .5rem;
  }
  
  .mr-1 {
    margin-right: .25rem !important;
  }
  
  .overviewanalytic-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .overviewanalytic-content-wrapper .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
  }
  
  .overviewanalytic-content-wrapper .overviewanalytic-side {
    filter: drop-shadow(0px -4px 12px rgba(0, 0, 0, 0.06));
    width: 250px;
  }
  
  .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list {
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-bottom: 2px solid #C3CAD9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list.active, .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
  
  .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list > button.overviewanalytic-cat-list-button {
    background-color: transparent;
    color: var(--color-primary);
    size: 3.5rem;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  
  .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list.active > button.overviewanalytic-cat-list-button,
  .overviewanalytic-content-wrapper .overviewanalytic-side .overviewanalytic-cat-list:hover > button.overviewanalytic-cat-list-button {
    color: #fff !important;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* margin-left: 2rem; */
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-left,
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right {
    display: flex;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right > p {
    color: var(--color-text-primary);
    font-weight: 600;
    margin: 0px
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right > div {
    display: flex;
    align-items: center;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right > div > span {
    font-weight: 600;
    color: var(--color-black);
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right > div::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin: 0px 0.5rem;
    background-color: var(--color-stroke-dark);
    display: inline-block;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right div.checkbox {
    margin-left: 0.5rem;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid var(--color-stroke-dark);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .5rem;
    color: #fff
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-right div.checkbox.checked {
    border: 1px solid var(--primary-button);
    background-color: var(--primary-button);
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-left > .overviewanalytic-tab {
    margin-right: .25rem;
    padding: .5rem 1.5rem;
    cursor: pointer;
    background-color: var(--color-border);
    color: var(--color-text-light);
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    transition: all 250ms ease-in-out;
  }
  
  .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-left > .overviewanalytic-tab.active, .overviewanalytic-content-wrapper > .overviewanalytic-content .overviewanalytic-tab-wrapper > .overviewanalytic-tab-left > .overviewanalytic-tab:hover {
    background-color: var(--primary-button);
    color: #fff;
    font-size: .95rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content {
    margin-top: 1rem;
    min-height: 300px;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk>div:not(:last-child) {
    margin-right: 1rem;
  }
  
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list {
    background-color: #fff;
    flex: 1;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0.5rem;
    max-width: 33% !important;
  }
  
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container {
    display: flex;
    align-items: flex-start;
    padding: .5rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details h4 {
    font-weight: 700;
    font-size: 1rem;
    color: var(--color-active);
    margin: 0.5rem 0rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details .overviewanalytic-playlist {
    margin-bottom: .5rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details .overviewanalytic-playlist,
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details .overviewanalytic-date {
    display: flex;
    align-items: center;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details .overviewanalytic-playlist > span,
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-details .overviewanalytic-date > span {
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: .8rem;
    color: var(--action-color);
    font-family: 'Inter', sans-serif;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list.dragged {
    opacity: .2;
    transition: all 250ms ease-in-out;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list.entered {
    border: 2px var(--color-navbar-active-border) dashed;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list-empty {
    background-color: transparent;
    flex: 1;
    display: flex;
    padding: 0;
  }
  
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk > div:not(:last-child) {
    margin-right: 1rem;
  }
  
  /* .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list:last-child {
    margin-left: .5rem;
  } */
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-bg-white-blueish);
    padding: .5rem 1rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-left {
    display: flex;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-left p:first-child {
    margin: 0px;
    color: var(--color-text-light);
    margin-right: 0.5rem;
    font-size: .8rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-left p:last-child {
    margin: 0px;
    color: var(--color-black);  
    font-size: .8rem;
    white-space: nowrap;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-right {
    display: flex;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-right > ul {
    display: flex;
    margin: 0px;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-footer .overviewanalytic-list-footer-right > ul > li button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-img,
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-container .overviewanalytic-list-video {
    width: 120px;
    height: 120px;
    object-fit: contain;
    border: 1px solid var(--color-stroke);
    border-radius: .5rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-top {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-top > li {
    margin-bottom: .25rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-top > li > i {
    margin-right: .5rem;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-bottom {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-bottom li {
    cursor: pointer;
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-bottom li:hover {
    color: var(--color-primary);
  }
  
  .overviewanalytic-content .overviewanalytic-list-content .overviewanalytic-list-chunk .overviewanalytic-list .overviewanalytic-list-details ul.overviewanalytic-list-bottom > li:not(:last-child) {
    margin-right: 1rem;
  }

 
  
  
  .playlist-wrapper > .playlist-dropdown {
    position: absolute;
    background-color: #fff;
    top: 2.8rem;
    box-shadow: 0px 6px 24px rgb(0 0 0 / 12%);
    border-radius: 0.25rem;
    width: 200px;
    left: 0;
    cursor: text !important;
   
  }
  
  .playlist-wrapper > .playlist-dropdown.playlist-dropdown-open {
    opacity: 1;
    z-index: 2;
    transition: opacity ease-in-out 250ms;
  }
  
  .playlist-wrapper > .playlist-dropdown.playlist-dropdown-closed {
    opacity: 0;
    z-index: -2;
    transition: opacity ease-in-out 250ms, z-index 250ms ease-in-out 150ms;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul {
    margin: 0px;
    max-height: 300px;
    overflow-y: auto;
  }
  .playlist-wrapper > .playlist-dropdown > .filter-dropdown {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li.divider {
    border-bottom: 1px solid var(--color-stroke-dark);
    margin: .5rem 1rem;
    padding: 0px;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li.header {
    padding: 0.5rem 1rem;
    background-color: var(--color-bg-white-blueish);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-active);
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li.header > i {
    margin-right: .5rem;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    color: var(--color-black);
    font-weight: 600;
    font-size: .8rem;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li.playlist-button {
    background-color: var(--primary-button);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li > div.radio,
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio {
    width: 15px;
    height: 15px;
    background-color: var(--color-bg-radio);
    margin-right: 0.5rem;
    border-radius: 15px;
    border: 1px solid var(--color-border-radio);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .playlist-wrapper{
    margin-right: 1rem;
  }
  .playlist-wrapper > .playlist-dropdown > ul > li > div.radio.active,
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio.active {
    border-color: var(--primary-button);
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li > div.radio > div.radio-checked,
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio > div.radio > div.radio-checked {
    width: 8px;
    height: 8px;
    background-color: var(--primary-button);
    border-radius: 8px;
  }
  
  
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio {
    display: flex;
    align-items: center;
  }
  
  .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .overviewanalytic-overlayed {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: var(--color-bg-overlay);
    position: absolute;
    border-radius: 0.25rem;
    display: flex;
    padding: 1rem;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .overviewanalytic-overlayed > .overviewanalytic-overlayed-check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--primary-button);
    color: #fff;
    border-radius: 20px;
  }
  
  .overviewanalytic .btn-group {
    display: flex;
  }
  
  .overviewanalytic .btn-group > button:not(:last-child) {
    margin-right: .5rem;
  }
  
  .overviewanalytic .btn-outline-primary:hover,
  .overviewanalytic .btn-outline-secondary:hover,
  .overviewanalytic .btn-outline-warning:hover,
  .overviewanalytic .btn-outline-danger:hover {
    opacity: .5;
  }
  
  .overviewanalytic .btn-outline-primary {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary-button);
    border-radius: 0.35rem;
    padding: .5rem 1rem;
    color: var(--primary-button);
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  
  .overviewanalytic .btn-outline-secondary  {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-button);
    border-radius: 0.35rem;
    padding: .5rem 1rem;
    color: var(--secondary-button);
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  
  .overviewanalytic .btn-outline-warning {
    display: flex;
    align-items: center;
    border: 1px solid var(--warning-button);
    border-radius: 0.35rem;
    padding: .5rem 1rem;
    color: var(--warning-button);
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  
  .overviewanalytic .btn-outline-danger {
    display: flex;
    align-items: center;
    border: 1px solid var(--danger-button);
    border-radius: 0.35rem;
    padding: .5rem 1rem;
    color: var(--danger-button);
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  
  .overviewanalytic .btn-outline-primary > svg,
  .overviewanalytic .btn-outline-warning > svg,
  .overviewanalytic .btn-outline-danger > svg {
    margin-right: .5rem;
  }
  
  .overviewanalytic .overviewanalytic-list-container-content {
    display: flex;
    flex-direction: column;
    padding: 0px 0.5rem;
  }
  
  .overviewanalytic .overviewanalytic-list-container-content > h1 {
    color: var(--color-active);
    font-weight: 700;
    font-size: 1rem;
  }
  
  .overviewanalytic .overviewanalytic-list-container-content > p {
    margin: 0px;
    color: var(--action-color);
  }
  
  .copied-popover {
    display: flex;
    align-items: center;
    margin: 0px;
  }
  
  .copied-popover > i {
    color: #fff;
    padding: .25rem;
    border-radius: 50%;
    background-color: var(--primary-button);
    margin-right: .5rem;
  }
  
  .custom-modal .custom-modal-content > h1.custom-modal-title {
    color: var(--color-active);
    font-weight: 600;
    font-size: 1rem;
  }
  
  .custom-modal .custom-modal-content > .custom-modal-details > ul {
    margin: 0px;
  }
  
  .custom-modal .custom-modal-content > .custom-modal-details > ul > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: var(--action-color);
  }
  
  .custom-modal .custom-modal-content > .custom-modal-details > ul > li > svg {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }