.directories {
  width: 100%;
}

.directories .directories-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.directories .directories-header .searchbar {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
}

.directories .directories-header .searchbar-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.directories .directories-header .searchbar > i {
  font-size: 1.5em;
  color: var(--color-active);
}

.directories .directories-header .searchbar > input.search-input {
  border: none;
  padding-left: 1rem;
  background-color: transparent;
}

.directories .directories-header .searchbar > input.search-input::placeholder {
  color: var(--color-secondary);
}

.directories .directories-header .searchbar > input.search-input:focus {
  outline: none;
}

.directories .directories-header .btn-primary {
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 10px;
}

.directories .directories-header .btn-primary:hover {
  opacity: 0.8;
}

.directories-card-wrapper {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* background-color: #fff; */
  /* filter: drop-shadow(0px 6px 40px rgba(0, 0, 0, 0.04)); */
}

.directories-card-list {
  /* padding: 1rem;
  background-color: #fff;
  display: flex;
  box-shadow: 0px 10px 7px 2px rgba(0,0,0, 0.04);
  border-radius: 0.5rem;
  flex: 1; */
}

.directories-card-list:not(:last-child) {
  margin-right: 1rem;
}

.directoriess-content{
  flex: 1 !important;
  overflow-y: scroll;
  max-height: 75vh;
  padding: 0rem 1rem;
}

.directories-card-list img {
  width: 100px;
  height: 100px;
  border-radius: 0.35rem;
  object-fit: contain;
  border: 1px solid var(--color-border);
}

.directories-card-list .directories-card-content {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.directories-card-list .directories-card-content p {
  margin: 0;
  color: var(--color-primary);
  font-weight: 700;
  font-size: 1rem;
}

.directories-card-list .directories-card-content .directories-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.directories-card-list .directories-card-content .directories-card-header .directories-card-button button,
.directories-card-list .directories-card-content .directories-card-header .directories-card-button a {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--color-black);
  margin-right: .5rem;
  cursor: directoriesnter;
}

.directories-card-list .directories-card-content .directories-card-header .directories-card-button i:not(:last-child) {
  margin-right: .5rem;
}

.directories-card-list .directories-card-content .directories-card-header p {
  margin: 0;
  font-weight: 500;
  color: var(--color-black);
}

.upload-wrapper p {
  font-weight: 600;
  color: var(--color-black);
  margin-bottom: 1.5rem;
}

.upload-drag-and-drop {
  padding: 1.5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: directoriesnter;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}
.upload-image-preview > .btn-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--color-bg-pink);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.upload-image-preview > img,
.upload-image-preview > video {
  width: 100%;
  border-radius: 10px;
  height: 140px;
  object-fit: contain;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.upload-drag-and-drop > p.upload-title {
  font-weight: 700;
  color: var(--color-text-light);
  margin-bottom: 0.5rem;
}

.upload-drag-and-drop > p.upload-title span.upload-highlight {
  color: var(--primary-button);
}

.upload-drag-and-drop > p.upload-subtitle {
  color: var(--color-text-gray-dark);
  font-weight: 400;
  font-size: .8rem;
}