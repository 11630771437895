.notification {
  font-family: 'Inter', sans-serif;
  padding: 2% 3%;
  width: 100%;
}

 .notification-dialog .ant-modal-content {
  padding: 0 !important;
}

.notification-dialog .ant-modal-content .ant-modal-header{
  padding: 1rem;
}
.notification-dialog .ant-modal-content .ant-modal-body{
  padding-inline: 1.5rem;
}
.notification-dialog .ant-modal-content .ant-modal-footer{
  background-color: #E2FEED;
  
}
.notification-dialog .ant-modal-content .ant-modal-footer .notification-footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  background-color: transparent;
}
 .modal-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
  .notification-dialog .row-data {
    width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 0.5px solid var(--color-light-grey);
}
  .notification-dialog .row-data-footer {
    width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.notification-dialog .row-data .label-preview, .notification-dialog .row-data-footer .label-preview{
  font-weight: 700;
}

.notification .notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.notification .notification-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.notification .notification-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.notification .notification-dropdown .child-dropdown {
  margin-right: 1rem ;
  width: max-content;
}

.notification .notification-download {
  display: flex;
}

.notification .notification-dropdown span {
  color: var(--color-active);
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5rem;
}


.notification .ant-picker {

  /* width: 12rem !important; */
  padding: 0.8rem 1rem;
  
  /* background-color: aqua; */
  border-color: var(--color-light-grey) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.notification .notification-dropdown .ant-select {
  display: inline-block;
  width: auto !important;
  /* background-color: aqua; */
  border-color: var(--color-black) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.notification .notification-dropdown .ant-select-selector {
  display: inline-block;
  height: 3rem !important;
  padding: 0.5rem 1.5rem !important;
  width: auto !important;
  font-weight: 600;
  font-size: 1rem ;
  background-color: transparent !important;
  color: var(--color-active);
}

.notification .notification-dropdown .ant-select-selector {
  display: inline-flex;
  height: 3rem !important;
  padding: 0.5rem 1.5rem !important;
  min-width: 12rem;
  width: auto !important;
  margin: 0 auto;
  align-items: center;
  font-weight: 600;
  font-size: 1rem ;
  background-color: transparent !important;
  color: var(--color-active);
}
.notification .notification-dropdown .ant-select-selection-item {
 display: flex;
 align-items: center;
}

.notification .search-bar, .notification .notification-menu > .notification-search .ant-picker {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem
}

.notification .notification-menu > .notification-search .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.notification .search-bar input::placeholder {
  color: var(--color-text-light) !important;
}

.notification .search-bar input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--color-input-focus) !important;
}

.notification .dropdown-tree {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
  width: 18%;
}

.notification .search-bar i {
  font-size: 1.5em;
  color: var(--color-active);
}

.notification .search-bar input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  color: var(--color-active);
}

.notification .search-bar input::placeholder {
  color: var(--color-secondary) !important;
}

.notification .ant-pagination-options-size-changer.ant-select {
  width: 8rem !important;
}

.notification table th {
  background-color: var(--color-navbar) !important;
  color: var(--color-black);
}

.notification table .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
}

.notification table tr {
  font-size: 0.8rem;
}

.notification table td.ant-table-cell {
  padding: 0.5rem;
}

.notification table .btn i {
  margin-right: 0.2rem;
}

.notification table .btn:hover {
  cursor: pointer;
}

.notification table .btn--edit .btn--edit:hover {
  color: var(--color-primary);
}
.notification table .btn--access .btn--access:hover {
  color: green;
}
.notification table .btn--delete .btn--delete:hover {
  color: red;
}

.notification .notification-limit {
  margin-bottom: 1rem;
}

.notification .notification-date-picker {
  width: auto;
  height: max-content;
}


.notification .notification-search {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
