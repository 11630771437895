.is-user-avatar {
  &.has-max-width {
    max-height: 7rem;
  }

  .user-avatar {
    height: 100%;
    max-height: 7rem;
    max-width: 7rem;
  }
}

.field-body {
  flex-grow: 3;
}

@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}
