.concierge-form {
  width: 100%;
}

.concierge-form h1{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
/* Chrome, Safari, Edge, Opera */
.concierge-form input::-webkit-outer-spin-button,
.concierge-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.concierge-form .ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}
.concierge-form .ql-container {
  border-radius:  0 0 0.5rem 0.5rem;
  background: var(--color-input);
}
/* Firefox */
.concierge-form input[type=number] {
  -moz-appearance: textfield;
}

.concierge-form .form-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-content: center;
  padding: 2.5rem 0rem;
}

.concierge-form .form-wrapper > div:first-child {
  width: 80%;
  margin-right: 1rem;
}

.concierge-form .form-wrapper > div:last-child {
  width: 80%;
  margin-left: 1rem;
}

.concierge-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}
.concierge-form .form-wrapper  .form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.concierge-form .form-wrapper  .form-info {
  color: var(--color-text-light);
  white-space: break-spaces;
}

.concierge-form .form-wrapper .form {
  margin-bottom: 1rem !important;
  flex: 1;
}

.concierge-form .upload-image-preview {
  position: relative;
  width: 100%;
}

.concierge-form .upload-image-preview > img,
.concierge-form .upload-image-preview > video {
  width: 100%;
  border-radius: 10px;
  height: 140px;
  object-fit: contain;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.concierge-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--color-bg-pink);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.concierge-form .upload-drag-and-drop {
  padding: 1.5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.ant-upload {
  width: 100%;
}


.concierge-form .form-wrapper .logo-form{
  width: 8rem;
}

.concierge-form .ant-color-picker-trigger {
  width: 100%;
  padding: 1.5rem 1rem;
  justify-content: flex-start;
}

/* .concierge-form .ant-select{
  width: 20% !important;
} */
.concierge-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.concierge-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.concierge-form .upload-drag-and-drop:hover,
.concierge-form .upload-drag-and-drop:hover > i,
.concierge-form .upload-drag-and-drop:hover > p,
.concierge-form .upload-drag-and-drop.active,
.concierge-form .upload-drag-and-drop.active > i,
.concierge-form .upload-drag-and-drop.active > p {
  border-color: var(--color-input-focus);
  /* color: var(--color-active); */
}


.concierge-form .upload-drag-and-drop > p.upload-title span.upload-highlight {
  color: var(--primary-button);
}

.concierge-form .upload-drag-and-drop > p.upload-subtitle {
  color: var(--color-text-gray-dark);
  font-weight: 400;
  font-size: .8rem;
}

.concierge-form .button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end !important;
  margin-top: 2rem;
}

.concierge-form .button-wrapper .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.concierge-form .button-wrapper .button-primary:disabled {
  opacity: 0.5;
  cursor: disabled;
}

.concierge-form .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.concierge-form .form-group {
  display: flex;
  align-items: center;
}

.concierge-form .form input[type='color'] {
  background-color: rgb(243, 156, 18);
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0px;
  border-radius: 5px;
}

.concierge-form .input-group {
  display: flex;
  align-items: center;
}

.concierge-form .input-group > div:last-child {
  background-color: var(--color-stroke);
  padding: 0.3rem 1em;
}

.concierge-form .card-adsign .card-title > p {
  font-weight: normal;
}

.concierge-form .card-adsign-content .button-link {
  background-color: transparent;
}

.concierge-form .card-checkbox span {
  font-weight: normal;
}

.concierge-form .card-adsign-content .copy-popup {
  right: 0;
}

.concierge-form .form .form-split {
  display: flex;
  justify-content: space-between;
}

.concierge-form .form .form-split div:first-child {
  margin-right: 2rem;
}

.concierge-form .form .form-split label.form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.concierge-form .form-group span {
  display: inline-block;
  margin: 0px 0.5rem;
}


.concierge-form input.ant-input, .concierge-form textarea.ant-input {
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.concierge-form input.ant-input::placeholder,
.concierge-form textarea.ant-input::placeholder {
  color: var(--color-text-light);
}

.concierge-form input.ant-input:focus, .concierge-form textarea.ant-input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-input-focus) !important;
}


.concierge-form .ant-picker {
  width: 100%;
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.concierge-form .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.concierge-form .ant-select-selector {
  width: 100%;
  background-color: var(--color-input) !important;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.15rem 1rem !important;
  height: auto !important;
}

.concierge-form .ant-select-open .ant-select-selector, .concierge-form .ant-select-focused .ant-select-selector {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.concierge-form .btn-primary {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: none;
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.ant-picker-range .ant-picker-clear {
  right: 41px !important;
}